.ff--serif { font-family: $font-family-serif !important; }
.ff--sans { font-family: $font-family-sans-serif !important; }

.txt--lg { font-size: 115% !important; }
.txt--md { font-size: inherit !important; }
.txt--sm { font-size: 85% !important; }

.bw0 { font-weight: $body-weight-normal !important; }
.bw1 { font-weight: $body-weight-bold !important; }
.bw2 { font-weight: $body-weight-bolder !important; }

.hw0 { font-weight: $heading-weight-normal !important; }
.hw1 { font-weight: $heading-weight-bold !important; }
.hw2 { font-weight: $heading-weight-bolder !important; }

.lh1 { line-height: 1.1em !important; }
.lh125 { line-height: 1.25em !important; }

.txt--left { text-align: left !important; }
.txt--right { text-align: right !important; }
.txt--center { text-align: center !important; }

.txt--up { text-transform: uppercase !important; }
.txt--cap { text-transform: capitalize !important; }

.lst { letter-spacing: -0.0525em !important; }
.lsr { letter-spacing: 0 !important; }
.lss { letter-spacing: 0.0525em !important; }