blockquote {
	margin: 0 0 15px;
	padding: 15px;
	font-size: 115%;
	line-height: $base-line-height;
	border-left: 5px solid #ccc;
	> *:first-child { margin-top: 0; }
	> *:last-child { margin-bottom: 0; }

	cite {
		font-size: 112%;
		font-style: normal;
		font-weight: $body-weight-bold;
		letter-spacing: -0.03em;
	}

	&.quoted {
		cite {
			font-style: italic;
			&::before {
				content: "— ";
			}
		}
	}

}

