.color--text { color: $color-text !important; }
.color--white { color: white !important; }
.color--primary { color: $brand-primary-light !important; }
.color--secondary { color: $brand-secondary !important; }
.color--link { color: $color-link !important; }
.color--review { color: $color-review !important; }
.color--success { color: $color-success !important; }
.color--danger { color: $color-danger !important; }

.bg--text { background-color: $color-text !important; }
.bg--white { background-color: $white !important; }
.bg--primary { background-color: $brand-primary !important; }
.bg--secondary { background-color: $brand-secondary !important; }
.bg--link { background-color: $color-link !important; }
.bg--review { background-color: $color-review !important; }
.bg--success { background-color: $color-success !important; }
.bg--danger { background-color: $color-danger !important; }
.bg--transparent { background-color: transparent !important; }
.bg--body { background-color: $body-bg !important;  }


.fill-text { color: $color-text !important; }
.fill-white { color: white !important; }
.fill-primary { color: $brand-primary-light !important; }
.fill-secondary { color: $brand-secondary !important; }
.fill-link { color: $color-link !important; }
.fill-review { color: $color-review !important; }
.fill-success { color: $color-success !important; }
.fill-danger { color: $color-danger !important; }

.fill-google { fill: $color-google; }
.fill-facebook { fill: $color-facebook; }
.fill-trip-advisor { fill: $color-trip-advisor; }
.fill-yelp { fill: $color-yelp; }
.fill-home-advisor { fill: $color-home-advisor; }
.fill-angi { fill: $color-angi; }