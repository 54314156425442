.gad-wrap {
	margin: 30px 0;
	display: flex;
	justify-content: space-between;

	div {
		display: block;
		padding: 5px;
		background-color: white;
		border: 1px solid #ddd;
	}

}